<template>
  <v-row class="m-0 p-0" v-bg:b>
    <v-col col="12">
      <v-card outlined class="mt-1 mb-0 pb-0">
        <v-card-title class="my-0 py-1">
          <p class="ms-1 my-0 fs-11pt f-raleway fw-700" v-c:P>
            <i class="btn-icon-left" v-i:duo#play#18 v-c:P></i>
            Live
          </p>
          <div class="left mt-0 mb-0 pt-0 ms-4" v-if="equipe != null">
            <span class="fs-10pt me-2">Equipe: </span>
            <avataronline v-for="(user,key,index) in equipe.pessoas" :key="index" :userID="user.id" :size="34"/>
          </div>
        </v-card-title>
        <!-- MENU -->
        <topmenu @modelToggle="modelToggle" :key="refresh"/>
      </v-card>

      <div class="mt-2" v-if="modelToggleSel =='inscricao'">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <bannerinscricao />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="mt-2" v-if="modelToggleSel =='indicacoes'">
        <indicacoes />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='indicacoesUCDs'">
        <indicacoesUCDs />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='importacao'">
        <importacao />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import { notification } from '@/components/notification/notification.js'
import avataronline from "@/components/avatar-online.vue";

export default {
  name: 'avaliacaohome',
  components: { avataronline,
    'topmenu': () => import('./topmenu/top-menu.vue'),
    'bannerinscricao': () => import('@/views/live/inscricaoUCD/banner-inscricao.vue'),
    'importacao': () => import('./importacao/importacao.vue'),
    'indicacoes': () => import('./indicacoes.vue'),
    'indicacoesUCDs': () => import('./indicacoes-ucds.vue'),
  },
  computed: {
    adminLive() {
      var equipe = this.$store.state.admin.equipesByName["Administração Live"];
      return (equipe.pessoas[this.userLog.id] != undefined);
    }
  },
  data () {
    return {
      refresh: 0,
      tab: null,
      equipe: {},
      modelToggleSel: "inscricao",
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    rdb.ref('/usuarios/equipes/-Mo3BOHRsHxCu3djFJ4J').on('value', function(snapshot) {
      self.equipe = snapshot.val();
      //console.log("self.equipe",self.equipe);
    });

  },
  methods: {

    modelToggle(model) {
      console.log("modelToggle",model);
      this.modelToggleSel = model;
    }

  }
}
</script>
